type Body = string |
  Parameters<typeof JSON.stringify>[0] |
  NonNullable<Parameters<typeof window.fetch>[1]>['body']

/**
 * AskFetch uses the `fetch` interface instead of XMLHttpRequest.
 * All functions return a promise for callback resolving or rejection.
 *
 * Usage
 * =====
 *
 * ```
 *  // Send request and receive response
 *  AskFetch.get(url).then(async response => {
 *    const data = await response.json()
 *  })
 *  // or
 *  const response = await AskFetch.get(url)
 *  const data = await response.json()
 * ```
 * ```
 *  // Use post
 *  AskFetch.post(url, {
 *    param1: true
 *  }).then(async response => {})
 * ```
 * ```
 *  // Query string shorthand
 *  AskFetch.get(url, {
 *    query_param: 'yes'
 *  })
 * ```
 *
 * Note
 * ====
 *
 * `AskFetch.postAsForm` is used for backward compatibility.
 *
 * Some endpoints are listening for x-www-form-urlencoded body from jQuery ajax
 * requests. `xxxAsForm` functions ensure a compatible behavior.
 */
export const AskFetch = new (class AskFetch {
  get (
    resource: Parameters<typeof window.fetch>[0],
    parameters: Record<string, unknown> = {},
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    this._applyDefaultHeaders(options)

    const url = new URL(resource instanceof Request
      ? resource.url
      : resource, document.location.href)
    url.search = new URLSearchParams([
      ...url.searchParams,
      ...this._toFlatObject(parameters)
    ]).toString()

    return fetch(url.toString(), options)
  }

  post (
    url: Parameters<typeof window.fetch>[0],
    body?: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'post'
    return this._jsonRequest(url, body, options)
  }

  put (
    url: Parameters<typeof window.fetch>[0],
    body?: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'put'
    return this._jsonRequest(url, body, options)
  }

  delete (
    url: Parameters<typeof window.fetch>[0],
    body?: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'delete'
    return this._jsonRequest(url, body, options)
  }

  /**
   * Sends as x-www-form-urlencoded
   */
  postAsForm (
    url: Parameters<typeof window.fetch>[0],
    body: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'post'
    return this._formRequest(url, body, options)
  }

  /**
   * Sends as x-www-form-urlencoded
   */
  putAsForm (
    url: Parameters<typeof window.fetch>[0],
    body: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'put'
    return this._formRequest(url, body, options)
  }

  /**
   * Sends as x-www-form-urlencoded
   */
  deleteAsForm (
    url: Parameters<typeof window.fetch>[0],
    body: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    options.method = 'delete'
    return this._formRequest(url, body, options)
  }

  private _applyDefaultHeaders (
    options: NonNullable<Parameters<typeof window.fetch>[1]>
  ): void {
    if (!options.headers) options.headers = {}
    const metaToken = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]'
    )
    const token = metaToken?.getAttribute('content')
    if (token) options.headers['X-CSRF-TOKEN'] = token
    options.credentials = 'same-origin'
  }

  private _jsonRequest (
    url: Parameters<typeof window.fetch>[0],
    body?: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    if (!options.headers) options.headers = {}
    this._applyDefaultHeaders(options)
    if (body) {
      options.headers['Content-Type'] = 'application/json'
      options.body = typeof body == 'string' ? body : JSON.stringify(body)
    }
    return fetch(url, options)
  }

  private _formRequest (
    url: Parameters<typeof window.fetch>[0],
    body: Body,
    options: Parameters<typeof window.fetch>[1] = {}
  ): ReturnType<typeof window.fetch> {
    if (!options.headers) options.headers = {}
    this._applyDefaultHeaders(options)
    options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    options.body = typeof body == 'string' ? body : new URLSearchParams(this._toFlatObject(body)).toString()
    return fetch(url, options)
  }

  /**
   * Transforms
   * ```
   * { query_object: { array: [1, 2, 3], foo: 'bar' } }
   * ```
   * into
   * ```
   *  [
   *    ["query_object[array][]","1"],
   *    ["query_object[array][]","2"],
   *    ["query_object[array][]","3"],
   *    ["query_object[foo]","bar"]
   *  ]
   * ```
   */
  private _toFlatObject (
    parameters: Record<string, unknown>,
    initialDepth = true
  ): [string, string][] {
    const flatObject: [string, string][] = []
    Object.keys(parameters).forEach(key => {
      const value = parameters[key]
      const flatParams = this._flattenKeyValue(value)
      flatParams.forEach(flatParamPair => {
        const fullKey = initialDepth
          ? `${key}${flatParamPair[0]}`
          : `[${key}]${flatParamPair[0]}`
        flatObject.push([fullKey, flatParamPair[1]])
      })
    })
    return flatObject
  }

  private _flattenKeyValue (
    value: unknown
  ): [string, string][] {
    const flatObject: [string, string][] = []
    if (typeof value === 'number') {
      flatObject.push(['', value.toString()])
    } else if (typeof value === 'string') {
      flatObject.push(['', value])
    } else if (value === null || value === undefined) {
      flatObject.push(['', ''])
    } else if (Array.isArray(value)) {
      value.forEach(arrayItem => {
        const flatParams = this._flattenKeyValue(arrayItem)
        flatParams.forEach(flatParam => {
          flatObject.push(['[]' + flatParam[0], flatParam[1]])
        })
      })
    } else if (this._isRecord(value)) {
      const flatParams = this._toFlatObject(value, false)
      flatParams.forEach(flatParam => {
        flatObject.push([flatParam[0], flatParam[1]])
      })
    } else {
      flatObject.push(['', `${value}`])
    }
    return flatObject
  }

  private _isRecord (obj: unknown): obj is Record<string, unknown> {
    return !!obj && typeof obj === 'object'
  }
})()
